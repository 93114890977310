import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import English from './translations/en/global.json';
import Vietnamese from './translations/vi/global.json';
import { LOCAL_STORAGE } from '@/constants';
import { LOCALE_ENUM } from '@/enums';

import companyProfileEN from './translations/en/company-profile.json';
import companyProfileVI from './translations/vi/company-profile.json';
import jobAdvertisementEN from './translations/en/job-advertisement.json';
import jobAdvertisementVI from './translations/vi/job-advertisement.json';
import messageEN from './translations/en/message.json';
import messageVI from './translations/vi/message.json';
import personalizationEN from './translations/en/personalization.json';
import personalizationVI from './translations/vi/personalization.json';
import candidateProfileEN from './translations/en/candidate-profile.json';
import candidateProfileVI from './translations/vi/candidate-profile.json';
import financeEN from './translations/en/finance-transaction.json';
import financeVI from './translations/vi/finance-transaction.json';
import feedbackEN from './translations/en/feedback.json';
import feedbackVI from './translations/vi/feedback.json';
import optionEN from './translations/en/option.json';
import optionVI from './translations/vi/option.json';
import notificationEN from './translations/en/notification.json';
import notificationVI from './translations/vi/notification.json';

export const resources = {
  en: {
    translation: English,
    companyProfile: companyProfileEN,
    jobAdvertisement: jobAdvertisementEN,
    message: messageEN,
    personalization: personalizationEN,
    candidateProfile: candidateProfileEN,
    finance: financeEN,
    feedback: feedbackEN,
    option: optionEN,
    notification: notificationEN,
  },
  vi: {
    translation: Vietnamese,
    companyProfile: companyProfileVI,
    jobAdvertisement: jobAdvertisementVI,
    message: messageVI,
    personalization: personalizationVI,
    candidateProfile: candidateProfileVI,
    finance: financeVI,
    feedback: feedbackVI,
    option: optionVI,
    notification: notificationVI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  ns: [
    'companyProfile',
    'translation',
    'jobAdvertisement',
    'message',
    'personalization',
    'candidateProfile',
    'finance',
    'option',
    'notification',
  ],
  lng: localStorage.getItem(LOCAL_STORAGE.LOCALE) || LOCALE_ENUM.VI,
  fallbackLng: LOCALE_ENUM.VI,
});

export const { t: translate } = i18n;

export default i18n;
