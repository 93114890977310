import { createSlice } from '@reduxjs/toolkit';

export type CandidateInterviewReduxStateType = {
  jobSelected: {
    id?: string;
  };
  candidateSelected: {
    id?: string;
    candidateJobApply?: any;
  };
};

const initialState: CandidateInterviewReduxStateType = {
  jobSelected: {},
  candidateSelected: {},
};

const candidateInterviewSlice = createSlice({
  name: 'candidateInterview',
  initialState: initialState,
  reducers: {
    setJobSelectedForInterview: (state, action) => {
      state.jobSelected.id = action.payload;
    },
    setCandidateSelectedForInterview: (state, action) => {
      state.candidateSelected.id = action.payload;
    },
    setCandidateJobApply: (state, action) => {
      state.candidateSelected.candidateJobApply = action.payload;
    },
  },
});

const { reducer, actions } = candidateInterviewSlice;

export const { setJobSelectedForInterview, setCandidateSelectedForInterview, setCandidateJobApply } = actions;

export default reducer;
