import { useAuth } from '@/hooks';
import { PATH_AFTER_LOGIN } from '@/routes/paths.routes';
import { ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

interface GuestGuardProps {
  children: ReactNode;
}

export const GuestGuard = ({ children }: GuestGuardProps) => {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const callbackKey = searchParams.get('callbackKey');

  if (callbackKey) {
    return <>{children}</>;
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  return <>{children}</>;
};
