/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import authService from './authService';
import { LOCAL_STORAGE } from '@/constants/localStorage';
import { toast } from 'sonner';
import { asyncLocalStorage, checkTokenExp } from '@/utils';
import { PATH_AUTH } from '@/routes/paths.routes';

// closure: to save the refreshTokenRequest
let refreshTokenRequest: any = null;

const axiosService = () => {
  const accessToken = asyncLocalStorage.getLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN) || '';
  const refreshToken = asyncLocalStorage.getLocalStorage(LOCAL_STORAGE.REFRESH_TOKEN) || '';

  const loadRefreshToken = async () => {
    try {
      const response = await authService.refreshToken(refreshToken);
      return response;
    } catch (error) {
      console.log('error when call refresh token: ', error);
      throw error;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
  };

  const axiosOptions = axios.create({
    baseURL: import.meta.env.API_URL,
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });

  // Truoc khi gui server
  axiosOptions.interceptors.request.use(
    async (config: any) => {
      // config.params = {
      //   ...config.params,
      //   organizationId,
      // };
      if (!checkTokenExp(accessToken)) {
        refreshTokenRequest = refreshTokenRequest ? refreshTokenRequest : loadRefreshToken();
        try {
          const response = await refreshTokenRequest;
          if (response) {
            localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, response.accessToken);
            localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, response.refreshToken);
            config.headers = {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + response.accessToken,
            };

            // reset token request for the next expiration
            refreshTokenRequest = null;
          }
        } catch (error: any) {
          refreshTokenRequest = null;
          if (!error.response) {
            toast.error('Không có kết nối đến server!');
          } else {
            if (error?.response?.status === 401) {
              localStorage.setItem(LOCAL_STORAGE.PREVIOUS_URL, window.location.pathname);
              // window.location.replace(PATH_NAME.LOGIN);
              handleLogout();
              toast.error('Phiên đăng nhập lỗi, vui lòng đăng nhập lại!');
            } else {
              toast.error('Lỗi phiên đăng nhập');
            }
          }
        }
        return config;
      }
      return config;
    },

    (error) => {
      Promise.reject(error);
    },
  );
  // Sau khi gui server
  axiosOptions.interceptors.response.use(
    (response) => {
      return response;
    },
    (errors) => {
      console.log(
        'Error:',
        JSON.stringify(
          {
            url: errors?.response?.config?.url,
            status: errors?.response?.status,
            method: errors?.response?.config?.method,
            data: errors?.response?.data,
            headers: errors?.response?.headers,
          },
          null,
          2,
        ),
      );

      if (errors?.response?.status === 401) {
        toast.error('Phiên đăng nhập đã hết hạn', {});
        localStorage.setItem(LOCAL_STORAGE.PREVIOUS_URL, window.location.pathname);
        window.location.replace(PATH_AUTH.login);
        handleLogout();
      } else {
        throw errors;
      }
    },
  );
  return axiosOptions;
};

export default axiosService;
