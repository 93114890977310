import { CALL_API_STATUS_ENUM } from '@/enums';
import { useState } from 'react';
import { toast } from 'sonner';
import NotificationService from '..';

const useUpdateNotificationById = () => {
  const [status, setStatus] = useState<CALL_API_STATUS_ENUM>(CALL_API_STATUS_ENUM.IDLE);

  const updateNotificationById = async (notificationId: string, isRead: boolean) => {
    setStatus(CALL_API_STATUS_ENUM.LOADING);
    try {
      const response = await NotificationService.updateNotificationById(notificationId, isRead);
      if (response) {
        toast.success('Cập nhật thành công !');
        setStatus(CALL_API_STATUS_ENUM.SUCCESS);
      }
    } catch (error) {
      console.error('useUpdateNotificationById - Update error:', error);
      toast.error('Có lỗi xảy ra khi update!');
      setStatus(CALL_API_STATUS_ENUM.ERROR);
    }
  };

  return {
    updateNotificationById,
    isLoading: status === CALL_API_STATUS_ENUM.LOADING,
    isSuccess: status === CALL_API_STATUS_ENUM.SUCCESS,
    isError: status === CALL_API_STATUS_ENUM.ERROR,
  };
};

export default useUpdateNotificationById;
