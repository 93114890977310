import { config } from '@/config';
import { useEffect, useRef } from 'react';
import OneSignal from 'react-onesignal';
import { Outlet } from 'react-router-dom';

export const PublicLayout = () => {
  const isFirstRenderRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      OneSignal.init({
        appId: config.ONE_SIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
      });

      isFirstRenderRef.current = true;
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};
