import { QUERY_KEY } from '@/constants/queryKey';
import { NotificationModel } from '@/models/notification';
import { NotificationSearchREQ } from '@/models/request/notification.request';
import { ListResponse } from '@/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import NotificationService from '..';

export const useSearchNotificationByCriteria = (
  params: NotificationSearchREQ,
  options?: Omit<UseQueryOptions<ListResponse<NotificationModel>>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<ListResponse<NotificationModel>>({
    queryKey: [QUERY_KEY.NOTIFICATIONS, params],
    queryFn: () => NotificationService.searchNotificationByCriteria(params),
    refetchOnWindowFocus: true,
    ...options,
  });
};
