import { AppDispatch } from '@/redux/store';
import { SheetMenu } from '../Sidebar/components/SheetMenu';
import { NotificationNav } from './components/Notification-nav';
import { UserNav } from './components/User-nav';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getUserCompany, getUserInfo } from '@/redux/slices/userSlice';

export function Navbar() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getUserCompany());
  }, []);

  return (
    <header className="sticky top-0 z-10 w-full bg-background/95 shadow backdrop-blur supports-[backdrop-filter]:bg-background/60 dark:shadow-secondary">
      <div className="mx-4 sm:mx-8 flex h-14 items-center">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
        </div>
        <div className="flex flex-1 items-center space-x-2 justify-end">
          {/* <ModeToggle /> */}
          <NotificationNav />
          <UserNav />
        </div>
      </div>
    </header>
  );
}
