import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  InputProps,
} from '@/components';
import { useFormContext } from 'react-hook-form';

interface RHFInputProps extends InputProps {
  name: string;
  placeholder?: string;
  title?: string;
  description?: string;
  required?: boolean;
  containerClassname?: string;
}

export const RHFInput = ({
  name,
  placeholder,
  title,
  description,
  required = false,
  containerClassname,
  ...props
}: RHFInputProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassname}>
          {title && <FormLabel required={required}>{title}</FormLabel>}
          {description && <FormDescription className="text-xs text-slate-500">{description}</FormDescription>}
          <FormControl>
            <Input {...props} placeholder={placeholder} {...field} value={field.value}  />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
