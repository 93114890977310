import { buttonVariants, ScrollArea, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components';
import { getMenuList } from '@/constants/menu-list';
import { cn } from '@/utils';
import { Ellipsis } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { CollapseMenuButton } from './CollapseMenuButton';
import FeedbackModal from './FeedbackModal';

interface MenuProps {
  isOpen: boolean | undefined;
}

export function Menu({ isOpen }: MenuProps) {
  const { pathname } = useLocation();
  const menuList = getMenuList(pathname);

  return (
    <>
      <ScrollArea className="[&>div>div[style]]:!block">
        <nav className="mt-8 h-full w-full">
          <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px-30px)] lg:min-h-[calc(100vh-32px-40px-32px-30px)] items-start  px-2">
            {menuList.map(({ groupLabel, menus }, index) => (
              <li className={cn('w-full', groupLabel ? 'pt-5' : '')} key={index}>
                {(isOpen && groupLabel) || isOpen === undefined ? (
                  <p className="text-sm font-medium text-muted-foreground px-2 pb-2 max-w-[248px] truncate">
                    {groupLabel}
                  </p>
                ) : !isOpen && isOpen !== undefined && groupLabel ? (
                  <TooltipProvider>
                    <Tooltip delayDuration={100}>
                      <TooltipTrigger className="w-full">
                        <div className="w-full flex justify-center items-center">
                          <Ellipsis className="h-5 w-5" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent side="right">
                        <p>{groupLabel}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <p className="pb-2"></p>
                )}
                {menus.map(({ href, label, icon: Icon, active, submenus, openNewTab }, index) =>
                  submenus.length === 0 ? (
                    <div className="w-full" key={index}>
                      <TooltipProvider disableHoverableContent>
                        <Tooltip delayDuration={100}>
                          <TooltipTrigger asChild>
                            <Link
                              to={href}
                              target={openNewTab ? '_blank' : ''}
                              className={cn(
                                buttonVariants({
                                  variant: active ? 'secondary' : 'ghost',
                                  size: 'sm',
                                }),
                                ' w-full justify-start h-8 mb-1',
                                active
                                  ? 'bg-primary text-white hover:bg-primary-800 hover:text-white  dark:bg-muted dark:hover:bg-muted'
                                  : '',
                              )}
                            >
                              <span className={cn(isOpen === false ? '' : 'mr-2')}>{Icon}</span>
                              <p
                                className={cn(
                                  'max-w-[200px] truncate',
                                  isOpen === false ? '-translate-x-96 opacity-0' : 'translate-x-0 opacity-100',
                                )}
                              >
                                {label}
                              </p>
                            </Link>
                          </TooltipTrigger>
                          {isOpen === false && <TooltipContent side="right">{label}</TooltipContent>}
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  ) : (
                    <div className="w-full" key={index}>
                      <CollapseMenuButton
                        icon={Icon}
                        label={label}
                        active={active}
                        submenus={submenus}
                        isOpen={isOpen}
                      />
                    </div>
                  ),
                )}
              </li>
            ))}
            {/* <li className="w-full grow flex items-end">
            <TooltipProvider disableHoverableContent>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <Button onClick={() => {}} variant="outline" className="w-full justify-center h-10 mt-5">
                    <span className={cn(isOpen === false ? '' : 'mr-4')}>
                      <LogOut size={18} />
                    </span>
                    <p className={cn('whitespace-nowrap', isOpen === false ? 'opacity-0 hidden' : 'opacity-100')}>
                      Sign out
                    </p>
                  </Button>
                </TooltipTrigger>
                {isOpen === false && <TooltipContent side="right">Sign out</TooltipContent>}
              </Tooltip>
            </TooltipProvider>
          </li> */}
          </ul>

          <FeedbackModal isCollapsible={isOpen} />
        </nav>
      </ScrollArea>
    </>
  );
}
