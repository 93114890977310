import { ContentLayout } from '@/components';
import { Sidebar } from '@/components/systems/Sidebar';
import { useSidebarCollapsed } from '@/hooks';
import { cn } from '@/utils';
import { Outlet } from 'react-router-dom';

export const DashboardLayout = () => {
  const [isCollapsed, setIsCollapsed] = useSidebarCollapsed();

  return (
    <div className="relative h-full overflow-hidden ">
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <main
        className={cn(
          ' min-h-screen  transition-[margin-left] ease-in-out duration-300 overflow-x-hidden',
          isCollapsed === false ? 'lg:ml-60' : 'lg:ml-16',
        )}
      >
        <ContentLayout>
          <Outlet />
        </ContentLayout>
      </main>
    </div>
  );
};
