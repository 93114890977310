import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  InputProps,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components';
import { LINK_TYPE_ENUM } from '@/enums';
import { translate } from '@/locales';
import { useFormContext } from 'react-hook-form';

const options = [
  { label: 'Facebook', value: LINK_TYPE_ENUM.FACE_BOOK },
  { label: 'GitHub', value: LINK_TYPE_ENUM.GIT },
  { label: 'YouTube', value: LINK_TYPE_ENUM.YOUTUBE },
  { label: 'Twitter', value: LINK_TYPE_ENUM.TWITTER },
  { label: 'LinkedIn', value: LINK_TYPE_ENUM.LINKEDIN },
  { label: 'Website', value: LINK_TYPE_ENUM.WEBSITE },
  { label: translate('linkOption.other', { ns: 'companyProfile' }), value: LINK_TYPE_ENUM.OTHER },
];

interface RHFLinkInputProps extends InputProps {
  name: string;
  urlPlaceholder?: string;
  typePlaceholder?: string;
  title?: string;
  description?: string;
  required?: boolean;
}

export const RHFLinkInput = ({
  name,
  urlPlaceholder,
  typePlaceholder,
  title,
  description,
  required = false,
  ...props
}: RHFLinkInputProps) => {
  const { control } = useFormContext();

  return (
    <FormItem className="w-full">
      {title && <FormLabel required={required}>{title}</FormLabel>}
      {description && (
        <FormDescription className="text-xs text-slate-500">{description}</FormDescription>
      )}
      <FormControl>
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-1 ">
            <FormField
              control={control}
              name={`${name}.type`}
              render={({ field }) => (
                <FormItem>
                  <Select onValueChange={field.onChange} value={field.value || ''}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={typePlaceholder} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        {options.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-2 ">
            <FormField
              control={control}
              name={`${name}.url`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...props}
                      placeholder={urlPlaceholder}
                      {...field}
                      value={field.value || ''}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </FormControl>
    </FormItem>
  );
};
