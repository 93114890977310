import { useState } from 'react';
import { toast } from 'sonner';
import CompanyService from '..';
import { CALL_API_STATUS_ENUM } from '@/enums';
import { translate } from '@/locales';
import { FeedbackREQ } from '@/models/request/feedback.request';

export const useFeedbackCompany = () => {
  const [status, setStatus] = useState<CALL_API_STATUS_ENUM>(CALL_API_STATUS_ENUM.IDLE);

  const feedbackCompany = async (data: FeedbackREQ) => {
    setStatus(CALL_API_STATUS_ENUM.LOADING);
    try {
      const response = await CompanyService.feedbackCompany(data);
      if (response) {
        toast.success(translate('apiSuccess.feedback', { ns: 'message' }));
        setStatus(CALL_API_STATUS_ENUM.SUCCESS);
      }
    } catch (error) {
      console.error('useUpdateCompanyProfileByCompanyID - Delete error:', error);
      toast.error(translate('apiError.feedback', { ns: 'message' }));
      setStatus(CALL_API_STATUS_ENUM.ERROR);
    }
  };

  return {
    feedbackCompany,
    isLoading: status === CALL_API_STATUS_ENUM.LOADING,
    isSuccess: status === CALL_API_STATUS_ENUM.SUCCESS,
    isError: status === CALL_API_STATUS_ENUM.ERROR,
  };
};
