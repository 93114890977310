import { Textarea, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components';
import { useFormContext } from 'react-hook-form';

interface RHFTextAreaProps {
  name: string;
  placeholder?: string;
  title?: string;
  description?: string;
  required?: boolean;
}

export const RHFTextArea = ({
  name,
  placeholder,
  title,
  description,
  required = false,
  ...props
}: RHFTextAreaProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel required={required}>{title}</FormLabel>
          <FormDescription className="text-xs text-slate-500">{description}</FormDescription>
          <FormControl>
            <Textarea placeholder={placeholder} className="resize-y w-full" {...field} {...props} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
