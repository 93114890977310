import qs from 'qs';
import axiosService from '../axiosService';
import { ListResponse, SearchParams } from '@/types';
import {
  SEARCH_USER_WITH_CRITERIA_URL,
  USER_CHANGE_PASSWORD_URL,
  USER_COMPANY_PROFILE_URL,
  USER_FAVOURITE_JOB_URL,
  USER_INFOR_URL,
  USER_URL,
} from '../apiUrl';
import { UserModel } from '@/models/user';
import { CompanyModel } from '@/models/company';
import { ChangePasswordModel } from '@/models/authen';

const UserService = {
  searchUserByCriteria: async (params: SearchParams): Promise<ListResponse<UserModel>> => {
    return axiosService()({
      url: SEARCH_USER_WITH_CRITERIA_URL,
      method: 'GET',
      params,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  getUserByUserID: async (id: string): Promise<UserModel> => {
    return axiosService()({
      url: `${USER_URL}/${id}`,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  getUserInfo: async (): Promise<UserModel> => {
    return axiosService()({
      url: `${USER_INFOR_URL}`,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  getGetCompany: async (): Promise<CompanyModel> => {
    return axiosService()({
      url: `${USER_COMPANY_PROFILE_URL}`,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  createUser: async (data: FormData) => {
    return axiosService()({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${USER_URL}`,
      method: 'POST',
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  updateUser: async (id: string, data: FormData) => {
    return axiosService()({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${USER_URL}/${id}`,
      method: 'PATCH',
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  deleteUser: async (ids: string[]) => {
    return axiosService()({
      url: `${USER_URL}`,
      method: 'DELETE',
      params: {
        ids: ids,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  changePassword: async (data: ChangePasswordModel) => {
    return axiosService()({
      method: 'PATCH',
      url: USER_CHANGE_PASSWORD_URL,
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  favoriteJob: async (ids: string[]) => {
    return axiosService()({
      url: `${USER_FAVOURITE_JOB_URL}`,
      method: 'PATCH',
      params: {
        favouriteJobIds: ids,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  removeFavoriteJob: async (ids: string[]) => {
    return axiosService()({
      url: `${USER_FAVOURITE_JOB_URL}`,
      method: 'PATCH',
      params: {
        removeFavouriteJobIds: ids,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default UserService;
