import { LOCAL_STORAGE } from '@/constants';
import { PATH_PUBLIC } from '@/routes/paths.routes';
import { asyncLocalStorage } from '@/utils';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface RoleGuardProps {
  children: React.ReactNode;
  rolePermission: string;
}

const RoleGuard = ({ children, rolePermission }: RoleGuardProps) => {
  const storedPermissionsString = asyncLocalStorage.getLocalStorage(LOCAL_STORAGE.PERMISSIONS);
  const storedPermissionsObject = storedPermissionsString
    ? JSON.parse(storedPermissionsString)
    : {};

  if (!storedPermissionsObject[rolePermission]) {
    return <Navigate to={PATH_PUBLIC.page403} replace />;
  }

  return <>{children}</>;
};

export { RoleGuard };
