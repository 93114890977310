import * as React from 'react';
import { cn } from '@/utils';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const handleInput = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto'; // Reset height to auto to shrink if needed
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height to scroll height
      }
    };

    React.useEffect(() => {
      handleInput(); // Initial adjustment on mount
    }, []);

    return (
      <textarea
        ref={(instance) => {
          textareaRef.current = instance;
          if (typeof ref === 'function') {
            ref(instance);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current = instance;
          }
        }}
        className={cn(
          'flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        onInput={handleInput}
        {...props}
      />
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
