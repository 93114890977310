import { LoadablePage } from '@/components/skeleton';
import { lazy } from 'react';

export const PublicPages = {
  Forbidden: LoadablePage(lazy(() => import('../pages/public/Page403'))),
  NotFound: LoadablePage(lazy(() => import('../pages/public/Page404'))),
  InternalServerError: LoadablePage(lazy(() => import('../pages/public/Page500'))),
  ComingSoon: LoadablePage(lazy(() => import('../pages/public/ComingSoon'))),
  MaintenanceError: LoadablePage(lazy(() => import('../pages/public/MaintenanceError'))),
  // IntroPage: LoadablePage(lazy(() => import('../pages/public/IntroPage'))),
  // GetStartedForCandidatePage: LoadablePage(lazy(() => import('../pages/public/GetStartedForCandidatePage'))),
  // GetStartedForEmployerPage: LoadablePage(lazy(() => import('../pages/public/GetStartedForEmployerPage'))),
};

export const AuthenticationPages = {
  LoginPage: LoadablePage(lazy(() => import('../pages/public/LoginPage'))),
};

export const DashboardPages = {
  //Overview
  DashboardPage: LoadablePage(lazy(() => import('../pages/auth/Overview'))),

  //Job Advertisement
  PostJobAdvertisementPage: LoadablePage(lazy(() => import('../pages/auth/JobAdvertisement/PostJobAdvertisement'))),
  ManageJobAdvertisementPage: LoadablePage(lazy(() => import('../pages/auth/JobAdvertisement/ManageJobAdvertisement'))),

  // Notifications
  NotificationPage: LoadablePage(lazy(() => import('../pages/auth/Notifications'))),

  //Candidate
  FindCandidate: LoadablePage(lazy(() => import('../pages/auth/Candidate/FindCandidate'))),
  InterviewAssessment: LoadablePage(lazy(() => import('../pages/auth/Candidate/InterviewAssessment'))),
  ManageCandidate: LoadablePage(lazy(() => import('../pages/auth/Candidate/ManageCandidate'))),

  //Finance
  FinanceAndTransactionsPage: LoadablePage(lazy(() => import('../pages/auth/FinanceAndTransactions'))),

  //Company
  CompanyProfilePage: LoadablePage(lazy(() => import('../pages/auth/Company/CompanyProfile'))),
  MembersPage: LoadablePage(lazy(() => import('../pages/auth/Company/Members/MembersTable'))),

  //Personalization
  PersonalizationPage: LoadablePage(lazy(() => import('../pages/auth/Personalization'))),
  AppearanceSettingsPage: LoadablePage(lazy(() => import('../pages/auth/Personalization/Appearance'))),
  ProfileSettingsPage: LoadablePage(lazy(() => import('../pages/auth/Personalization/Profile'))),
  SecuritySettingsPage: LoadablePage(lazy(() => import('../pages/auth/Personalization/Security'))),
};
