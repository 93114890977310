import { PERMISSION_ENUM } from '@/enums/roleKey';
import { translate } from '@/locales';
import { PATH_AFTER_LOGIN, PATH_DASHBOARD } from '@/routes/paths.routes';
import {
  IconAd2,
  IconBrandTabler,
  IconBuildingCommunity,
  IconCalendarClock,
  IconCertificate,
  IconChartHistogram,
  IconChartPie,
  IconConfetti,
  IconCreditCardPay,
  IconInfoSquareRounded,
  IconMessage,
  IconMessageCog,
  IconMessageReport,
  IconMessageShare,
  IconReport,
  IconScale,
  IconShieldCog,
  IconUserCog,
  IconUserSearch,
  IconUsers,
  IconUsersGroup,
  IconWallet,
} from '@tabler/icons-react';

export interface NavLink {
  title: string;
  notifications?: number;
  permissionKey?: string;
  href: string;
  icon: JSX.Element;
}

export interface SideLink extends NavLink {
  sub?: NavLink[];
}

export const SIDEBAR_LINK: SideLink[] = [
  {
    title: translate('systems.elements.sidebar.content.statisticalReport'),
    notifications: 20,
    href: PATH_AFTER_LOGIN,
    icon: <IconChartPie size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
  },
  //Job Advertisement
  {
    title: translate('systems.elements.sidebar.content.jobAdvertisement'),
    notifications: 20,
    href: PATH_DASHBOARD.jobAdvertisement.root,
    icon: <IconMessage size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,

    sub: [
      {
        title: translate('systems.elements.sidebar.content.postJobAdvertisement'),
        notifications: 0,
        href: PATH_DASHBOARD.jobAdvertisement.postJobAdvertisement,
        icon: <IconMessageShare size={22} />,
        permissionKey: PERMISSION_ENUM.JOB_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.manageJobAdvertisement'),
        notifications: 0,
        href: PATH_DASHBOARD.jobAdvertisement.manageJobAdvertisement.list,
        icon: <IconMessageCog size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      // {
      //   title: translate('systems.elements.sidebar.content.moderateJobAdvertisement'),
      //   notifications: 20,
      //   href: '/sign-up',
      //   icon: <IconMessageCheck size={22} />,
      //   permissionKey: PERMISSION_ENUM.JOB_VIEW,
      // },
    ],
  },
  {
    title: translate('systems.elements.sidebar.content.candidateProfile'),
    notifications: 0,
    href: '',
    icon: <IconUsers size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,

    sub: [
      {
        title: translate('systems.elements.sidebar.content.findCandidates'),
        notifications: 0,
        href: PATH_DASHBOARD.candidate.findCandidate,
        icon: <IconUserSearch size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: 'Quản lý ứng viên',
        notifications: 0,
        href: PATH_DASHBOARD.candidate.manageCandidate,
        icon: <IconUserSearch size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.interviewAndAssessment'),
        notifications: 0,
        href: PATH_DASHBOARD.candidate.interviewAssessment,
        icon: <IconUsersGroup size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
    ],
  },
  {
    title: 'Dịch vụ Premium',
    notifications: 0,
    href: '',
    icon: <IconConfetti size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    sub: [
      {
        title: 'Tìm kiếm ứng viên nâng cao',
        notifications: 0,
        href: '/sign-in-2',
        icon: <IconAd2 size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
    ],
  },
  {
    title: translate('systems.elements.sidebar.content.media'),
    notifications: 0,
    href: '',
    icon: <IconConfetti size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,

    sub: [
      {
        title: translate('systems.elements.sidebar.content.postContent'),
        notifications: 0,
        href: '/sign-in-2',
        icon: <IconAd2 size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.evaluationManagement'),
        notifications: 0,
        href: '/sign-up',
        icon: <IconMessageReport size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
    ],
  },
  {
    title: translate('systems.elements.sidebar.content.walletAndInvoices'),
    notifications: 0,
    href: '',
    icon: <IconWallet size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,

    sub: [
      {
        title: translate('systems.elements.sidebar.content.contractsAndInvoices'),
        notifications: 0,
        href: '/sign-up',
        icon: <IconReport size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.depositAndWithdrawal'),
        notifications: 0,
        href: '/sign-in-2',
        icon: <IconCreditCardPay size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.transactionHistory'),
        notifications: 0,
        href: '/sign-up',
        icon: <IconCalendarClock size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
    ],
  },

  {
    title: translate('systems.elements.sidebar.content.helpAndInformation'),
    notifications: 0,
    href: PATH_DASHBOARD.personalization.root,
    icon: <IconInfoSquareRounded size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,

    sub: [
      {
        title: translate('systems.elements.sidebar.content.legalInformation'),
        notifications: 0,
        href: '/sign-in-2',
        icon: <IconScale size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.marketAndDevelopment'),
        notifications: 0,
        href: '',
        icon: <IconChartHistogram size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
    ],
  },

  //Company
  {
    title: translate('systems.elements.sidebar.content.company'),
    notifications: 0,
    href: PATH_DASHBOARD.company.root,
    icon: <IconBuildingCommunity size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    sub: [
      {
        title: translate('systems.elements.sidebar.content.companyProfile'),
        notifications: 0,
        href: PATH_DASHBOARD.company.profile.list,
        icon: <IconCertificate size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.members'),
        notifications: 0,
        href: PATH_DASHBOARD.company.members.list,
        icon: <IconUserCog size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
      {
        title: translate('systems.elements.sidebar.content.rolePermission'),
        notifications: 0,
        href: PATH_DASHBOARD.company.rolePermissions.list,
        icon: <IconShieldCog size={22} />,
        permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
      },
    ],
  },
  //Personalization
  {
    title: translate('systems.elements.sidebar.content.personalization'),
    notifications: 20,
    href: PATH_DASHBOARD.personalization.profile,
    icon: <IconBrandTabler size={22} />,
    permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
  },
];
