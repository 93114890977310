import { NotificationModel } from '@/models/notification';
import { NotificationSearchREQ } from '@/models/request/notification.request';
import { ListResponse } from '@/types';
import { NOTIFICATION_URL, NOTIFICATION_URL_SEARCH_CRITERIA } from '../apiUrl';
import axiosService from '../axiosService';

const NotificationService = {
  searchNotificationByCriteria: async (params: NotificationSearchREQ): Promise<ListResponse<NotificationModel>> => {
    return axiosService()({
      method: 'GET',
      url: NOTIFICATION_URL_SEARCH_CRITERIA,
      params,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  updateNotificationById: async (notificationId: string, isRead: boolean) => {
    return axiosService()({
      method: 'PATCH',
      url: `${NOTIFICATION_URL}/${notificationId}/update-status`,
      data: isRead
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default NotificationService;
