export const asyncLocalStorage = {
  setLocalStorage(key: string, value: string) {
    return localStorage.setItem(key, value);
  },

  getLocalStorage(key: string) {
    return localStorage.getItem(key);
  },

  removeLocalStorage(key: string) {
    return localStorage.removeItem(key);
  },
};
