import { Navbar } from '../Navbar';

interface ContentLayoutProps {
  children: React.ReactNode;
}

export function ContentLayout({ children }: ContentLayoutProps) {
  return (
    <div className="relative flex h-full w-full flex-col md:h-[100vh]">
      <Navbar />
      <div className=" p-4 ">{children}</div>
    </div>
  );
}
