import { cn } from '@/utils';
import { Menu } from './components/Menu';
import { SidebarToggle } from './components/SidebarToggle';
import { Avatar, AvatarFallback, AvatarImage, Button } from '@/components/common';
import { Link } from 'react-router-dom';
import HRDept from '@/assets/logo/HRDept.png';
import HR from '@/assets/logo/HR.png';
import { PATH_AFTER_LOGIN } from '@/routes/paths.routes';

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Sidebar({ isCollapsed, setIsCollapsed }: SidebarProps) {
  return (
    <aside
      className={cn(
        'fixed top-0 left-0  h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 z-50',
        isCollapsed === false ? 'w-60' : 'w-16',
      )}
    >
      <SidebarToggle isOpen={!isCollapsed} setIsOpen={setIsCollapsed} />
      <div className="relative flex flex-col  py-4 overflow-y-auto shadow-md dark:shadow-zinc-800">
        <Button className={cn('transition-transform ease-in-out duration-300 mb-1')} variant="link">
          <Link to={PATH_AFTER_LOGIN} className="flex items-center gap-2">
            <Avatar className={`transition-all rounded ${isCollapsed ? 'h-9 w-10' : 'h-14 w-40'}`}>
              <AvatarImage src={isCollapsed ? HR : HRDept} alt={''} />
              <AvatarFallback>{'HRDept'}</AvatarFallback>
            </Avatar>
          </Link>
        </Button>
        <Menu isOpen={!isCollapsed} />
      </div>
    </aside>
  );
}
