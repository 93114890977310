import { format, formatDistanceToNow, getTime } from 'date-fns';
import vi from 'date-fns/locale/vi';
import { PATTERN } from './regex.utils';

export const DATE_FORMAT_YYYYMM = 'yyyy-MM-dd';
export const DATE_FORMAT_YYYYMMDDTHHMMSS = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT_DDMMYYYY = 'dd/MM/yyyy';

export const DATE_FORMAT_HHMM = 'HH:mm';

export const DATE_FORMAT_HHMMSS_DDMMYYYY = 'HH:mm dd/MM/yyyy';

export const DATE_FORMAT_DDMMYYYY_HHMM = 'dd/MM/yyyy HH:mm';
export const DATE_FORMAT_DDMMYYYY_HHMMSS = 'dd/MM/yyyy HH:mm:ss';

export function fDateWithT(date: string, formatDate: string): string {
  return format(new Date(date), formatDate);
}

export function fDate(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fTimestamp(date: string): number {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: string): string {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTime(date: string) {
  return format(new Date(date), 'dd MMM yyyy HH:mm', { locale: vi });
}

export function fToNow(date: string): string {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getFullDateTime(dateTime: string): string {
  const d = new Date(dateTime);
  d.setHours(d.getHours() + 7);
  return d.toISOString();
}

export function fCustomCalendarValueToSubmit(date: string): string {
  const [day, month, year] = date.split('/').map(Number);

  return format(new Date(year, month - 1, day), DATE_FORMAT_YYYYMM);
}

export function checkIsCorrectDate(value: string): boolean {
  const [day, month, year] = value.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date &&
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    PATTERN.DATE_REGEX.test(value)
  );
}
