import { CompanyModel } from '@/models/company';
import { UserModel } from '@/models/user';
import UserService from '@/services/userService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  user: UserModel;
  userCompany: CompanyModel;
};

const initialState: initialStateType = {
  user: {},
  userCompany: {},
};

export const getUserInfo = createAsyncThunk('user/getUserInfo', async (_, { dispatch }) => {
  try {
    const userRes = await UserService.getUserInfo();
    dispatch(setUserInfo(userRes));
  } catch (error) {
    console.log('Error when getUserInfo', error);
  }
});

export const getUserCompany = createAsyncThunk('user/getUserCompany', async (_, { dispatch }) => {
  try {
    const userCompanyRes = await UserService.getGetCompany();
    dispatch(setUserCompany(userCompanyRes));
  } catch (error) {
    console.log('Error when getUserInfo', error);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    setUserCompany: (state, action) => {
      state.userCompany = action.payload;
    },
    resetUserInfo: () => {
      return initialState;
    },
  },
});

const { reducer, actions } = userSlice;

export const { setUserInfo, setUserCompany, resetUserInfo } = actions;

export default reducer;
