import { Star } from 'lucide-react';
import { Dispatch, SetStateAction } from 'react';

interface StarRatingProps {
  rating: number;
  onRatingChange: Dispatch<SetStateAction<number>>;
}

export default function StarRating({ rating, onRatingChange }: StarRatingProps) {
  return (
    <div className="flex items-center space-x-1 -translate-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          type="button"
          onClick={() => onRatingChange(star)}
          className={`focus:outline-none focus:bg-yellow-50 focus:ring-primary rounded-full p-1 ${
            star <= rating ? 'text-yellow-400' : 'text-gray-300'
          }`}
        >
          <Star className="w-8 h-8 fill-current" />
        </button>
      ))}
    </div>
  );
}
