import { ChevronLeft } from 'lucide-react';

import { cn } from '@/utils';
import { Button } from '@/components';

interface SidebarToggleProps {
  isOpen: boolean | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SidebarToggle({ isOpen, setIsOpen }: SidebarToggleProps) {
  return (
    <div className="invisible lg:visible absolute top-[18px] -right-[14px] z-40">
      <Button onClick={() => setIsOpen((prev) => !prev)} className="rounded-lg w-5 h-5" variant="outline" size="icon">
        <ChevronLeft
          className={cn(
            'h-4 w-4 transition-transform ease-in-out duration-700',
            isOpen === false ? 'rotate-180' : 'rotate-0',
          )}
        />
      </Button>
    </div>
  );
}
