import { useState } from 'react';

import { cn } from '@/utils';
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu';

import {
  Button,
  buttonVariants,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components';
import { IconChevronDown, IconPoint } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

type Submenu = {
  href: string;
  label: string;
  active: boolean;
};

interface CollapseMenuButtonProps {
  icon: JSX.Element;
  label: string;
  active: boolean;
  submenus: Submenu[];
  isOpen: boolean | undefined;
}

export function CollapseMenuButton({ icon: Icon, label, active, submenus, isOpen }: CollapseMenuButtonProps) {
  const isSubmenuActive = submenus.some((submenu) => submenu.active);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isSubmenuActive);

  return isOpen ? (
    <Collapsible open={isCollapsed} onOpenChange={setIsCollapsed} className="w-full">
      <CollapsibleTrigger className="[&[data-state=open]>div>div>svg]:rotate-180 mb-1" asChild>
        <Button
          variant={active ? 'secondary' : 'ghost'}
          size={'sm'}
          className={cn(
            'w-full justify-start h-8',
            active
              ? 'bg-primary text-white hover:bg-primary hover:text-white  dark:bg-muted dark:hover:bg-muted'
              : 'hover:bg-transparent ',
          )}
        >
          <div className="w-full items-center flex justify-between">
            <div className="flex items-center">
              <span className="mr-2">{Icon}</span>
              <p
                className={cn(
                  'max-w-[150px] truncate',
                  isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0',
                )}
              >
                {label}
              </p>
            </div>
            <div
              className={cn('whitespace-nowrap', isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0')}
            >
              <IconChevronDown size={18} className="transition-transform duration-200" />
            </div>
          </div>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
        {submenus.map(({ href, label, active: activeSubmenu }, index) => {
          return (
            <Link
              key={index}
              to={href}
              className={cn(
                buttonVariants({
                  variant: activeSubmenu ? 'secondary' : 'ghost',
                  size: 'sm',
                }),
                'w-full justify-start h-8 mb-1',
                activeSubmenu
                  ? 'bg-primary text-white hover:bg-primary-800 hover:text-white  dark:bg-muted dark:hover:bg-muted'
                  : ' hover:bg-muted ',
              )}
            >
              <span className="mr-2 ml-2">
                <IconPoint size={18} />
              </span>
              <p
                className={cn(
                  'max-w-[170px] truncate line-clamp-1',
                  isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0',
                )}
              >
                {label}
              </p>
            </Link>
          );
        })}
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <DropdownMenu>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button
                variant={active ? 'secondary' : 'ghost'}
                size={'sm'}
                className={cn(
                  'w-full justify-start h-10 mb-1',
                  active
                    ? 'bg-primary text-white hover:bg-primary-800 hover:text-white  dark:bg-muted dark:hover:bg-muted'
                    : ' hover:bg-muted ',
                )}
              >
                <div className="w-full items-center flex justify-between">
                  <div className="flex items-center">
                    <span className={cn(isOpen === false ? '' : 'mr-4')}>{Icon}</span>
                    <p className={cn('max-w-[200px] truncate', isOpen === false ? 'opacity-0' : 'opacity-100')}>
                      {label}
                    </p>
                  </div>
                </div>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="right" align="start" alignOffset={2}>
            {label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenuContent side="right" sideOffset={10} align="start">
        <DropdownMenuLabel className="max-w-[190px] truncate">{label}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {submenus.map(({ href, label, active: activeSubmenu }, index) => (
          <DropdownMenuItem
            key={index}
            asChild
            className={cn(
              activeSubmenu
                ? 'bg-primary text-white hover:bg-primary-800 hover:text-white  dark:bg-muted dark:hover:bg-muted'
                : ' hover:bg-muted ',
            )}
          >
            <Link className="cursor-pointer" to={href}>
              <p className="max-w-[180px] truncate">{label}</p>
            </Link>
          </DropdownMenuItem>
        ))}
        <DropdownMenuArrow className="fill-border" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
