export enum QUERY_KEY {
  //BANK ACCOUNT
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  BANK_ACCOUNTS_FROM_VIET_QR = 'BANK_ACCOUNTS_FROM_VIET_QR',
  MY_BANK_ACCOUNTS = 'MY_BANK_ACCOUNTS',

  //USER
  USERS = 'USERS',
  USER_DETAIL = 'USERS_DETAIL',
  USER_PROFILE = 'USERS_PROFILE',

  // CV
  CANDIDATE_CV = 'CANDIDATE_CV',

  //JOB
  JOBS = 'JOBS',
  JOB_DETAIL = 'JOB_DETAIL',
  COUNT_ALL_STATUS_BY_JOB_URL = 'COUNT_ALL_STATUS_BY_JOB_URL',

  //COMPANY
  COMPANIES = 'COMPANIES',
  COMPANY_PROFILE = 'COMPANY_PROFILE',

  //CAREER
  CAREERS = 'CAREERS',
  CAREER_CATEGORIES = 'CAREER_CATEGORIES',

  //CANDIDATE
  CANDIDATES = 'CANDIDATES',

  //CANDIDATE
  CANDIDATE_CURRICUMUM_VITAES = 'CANDIDATE_CURRICUMUM_VITAES',
  COUNT_CANDIDATE_CURRICUMUM_VITAES = 'COUNT_CANDIDATE_CURRICUMUM_VITAES',

  CANDIDATE_JOB_APPLY = 'CANDIDATE_JOB_APPLY',
  CANDIDATE_JOB_APPLY_DETAIL = 'CANDIDATE_JOB_APPLY_DETAIL',

  //TRANSACTION HISTORY
  TRANSACTION_HISTORIES = 'TRANSACTION_HISTORIES',
  TRANSACTION_HISTORY_DETAIL = 'TRANSACTION_HISTORY_DETAIL',

  //TRANSACTION REQUEST
  TRANSACTION_REQUESTS = 'TRANSACTION_REQUESTS',

  //PAYMENT
  PAYMENT_INFO = 'PAYMENT_INFO',

  // CANDIDATE INTERVIEW RESULT
  CANDIDATE_INTERVIEW_RESULT = 'CANDIDATE_INTERVIEW_RESULT',

  // NOTIFICATION
  NOTIFICATIONS = 'NOTIFICATIONS',
}
