/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOCAL_STORAGE } from '@/constants';
import { asyncLocalStorage, checkTokenExp } from '@/utils';

interface AuthResult {
  isAuthenticated: boolean;
  user?: any; // Replace 'any' with the actual type of your user object
}

const useAuth = (): AuthResult => {
  try {
    const accessToken = asyncLocalStorage.getLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);

    if (accessToken) {
      if (checkTokenExp(accessToken)) {
        return { isAuthenticated: true };
      }
    }

    return { isAuthenticated: false };
  } catch (error) {
    console.error('Error fetching authentication details:', error);
    return { isAuthenticated: false };
  }
};

export default useAuth;
