import { createSlice } from '@reduxjs/toolkit';

export type CandidateManageReduxStateType = {
  candidateResumseLink: string;
};

const initialState: CandidateManageReduxStateType = {
  candidateResumseLink: '',
};

const candidateInterviewSlice = createSlice({
  name: 'candidateResumse',
  initialState: initialState,
  reducers: {
    setCandidateResumseLink: (state, action) => {
      state.candidateResumseLink = action.payload;
    },
  },
});

const { reducer, actions } = candidateInterviewSlice;

export const { setCandidateResumseLink } = actions;

export default reducer;
