import { ForgotPasswordModel, ResetPasswordModel, SignInModel, VerifyOTPModel } from '@/models/authen';
import axios from 'axios';
import {
  FORGOT_PASSWORD_URL,
  NEW_PASSWORD_URL,
  REFRESH_TOKEN_URL,
  SIGN_IN_URL,
  VERIFY_COMPANY_URL,
  VERIFY_OTP_URL,
} from '../apiUrl';
import { LoginRESP, VerifyCompanyRESP } from '@/models/response/auth.response';

const authService = {
  signIn: async (payload: SignInModel): Promise<LoginRESP> => {
    return axios({
      url: SIGN_IN_URL,
      method: 'POST',
      data: payload,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },

  refreshToken: async (refreshToken: string) => {
    return axios({
      method: 'POST',
      url: REFRESH_TOKEN_URL,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: refreshToken,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  verifyOTP: async (data: VerifyOTPModel) => {
    return axios({
      method: 'PATCH',
      url: `${VERIFY_OTP_URL}?otp=${data.otp}&token=${data.token}`,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  forgotPassword: async (data: string): Promise<ForgotPasswordModel> => {
    return axios({
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      url: `${FORGOT_PASSWORD_URL}`,
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  resetPassword: async (data: ResetPasswordModel) => {
    return axios({
      method: 'PATCH',
      url: `${NEW_PASSWORD_URL}`,
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  verifyCompany: async (callbackKey: string): Promise<VerifyCompanyRESP> => {
    return axios({
      method: 'GET',
      url: `${VERIFY_COMPANY_URL}`,
      params: {
        callbackKey,
      },
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default authService;
