import { BrowserRouter } from 'react-router-dom';
import { Router } from './routes';
import { ThemeProvider } from './components/systems/theme/theme-provider';
import { Toaster } from 'sonner';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { THEMES } from './models/theme';
import store from './redux/store';
import { OnbordaProvider } from './components';

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider defaultTheme={THEMES.LIGHT} storageKey="vite-ui-theme">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <OnbordaProvider>
              <Router />
            </OnbordaProvider>
            <Toaster richColors />
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
