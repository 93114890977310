import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components';
import { QUERY_KEY } from '@/constants/queryKey';
import { NOTIFICATION_EVENT_TYPE_ENUM } from '@/enums';
import { CompanyModel } from '@/models/company';
import { NotificationModel } from '@/models/notification';
import { RootState } from '@/redux/store';
import { useSearchNotificationByCriteria } from '@/services/notificationService/hooks/useSearchNotifcationByCriteria';
import useUpdateNotificationById from '@/services/notificationService/hooks/useUpdateNotificationById';
import { DATE_FORMAT_DDMMYYYY_HHMM, fDateWithT } from '@/utils';
import { IconBell, IconChecks, IconCircleFilled, IconInnerShadowTopRight } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { Bell } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface NotificationItemProps {
  isNew: boolean;
  notification: NotificationModel;
  hanldeUpdateNotification: (notificationId: string) => void;
  renderMessageTitle: (eventType: NOTIFICATION_EVENT_TYPE_ENUM) => string | undefined;
}

type TPageParams = {
  pageIndex: number;
  pageSize: number;
};

export function NotificationItem({
  isNew = false,
  notification,
  hanldeUpdateNotification,
  renderMessageTitle,
}: NotificationItemProps) {
  return (
    <DropdownMenuItem>
      <div
        className={`w-full flex items-center space-x-4 rounded-md p-2 hover:bg-accent hover:text-accent-foreground ${
          isNew ? 'bg-accent' : 'opacity-70'
        }`}
      >
        <Bell className="h-5 w-5" />
        <div className="space-y-1 w-full">
          <p className="text-sm font-medium leading-none flex  justify-between">
            {renderMessageTitle(notification.eventType)}{' '}
            {isNew && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <IconInnerShadowTopRight
                      onClick={(e) => {
                        e.stopPropagation();
                        hanldeUpdateNotification(notification.id);
                      }}
                      color="blue"
                      className="cursor-pointer"
                      size={15}
                    />
                  </TooltipTrigger>
                  <TooltipContent>Đánh dấu đã đọc</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </p>
          <p className="text-sm text-muted-foreground">{notification.message}</p>
          <p className={`text-xs ${isNew ? 'text-blue-700 font-semibold' : 'text-muted-foreground'} `}>
            {fDateWithT(notification.createdDate, DATE_FORMAT_DDMMYYYY_HHMM)}
          </p>
        </div>
      </div>
    </DropdownMenuItem>
  );
}

export function NotificationNav() {
  const { t: translate } = useTranslation('translation');
  const userCompany = useSelector<RootState, CompanyModel>((state) => state.userStore.userCompany);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { updateNotificationById } = useUpdateNotificationById();

  const [pageParams] = useState<TPageParams>({
    pageIndex: 0,
    pageSize: 5,
  });

  const [open, setOpen] = useState<boolean>(false);

  const hanldeUpdateNotification = useCallback(
    async (notificationId: string) => {
      await updateNotificationById(notificationId, true);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.NOTIFICATIONS],
      });
    },
    [queryClient, updateNotificationById],
  );

  const { data: notificationsData, isLoading: isLoadingNotifications } = useSearchNotificationByCriteria(
    {
      receiverId: userCompany.id ?? '',
      pageIndex: pageParams.pageIndex,
      pageSize: pageParams.pageSize,
    },
    {
      enabled: Boolean(userCompany.id),
    },
  );

  const notificationList = useMemo(() => {
    if (notificationsData && notificationsData.content && notificationsData.content.length > 0) {
      return notificationsData.content;
    }
    return [];
  }, [notificationsData]);

  const havingNewMessage = useMemo(() => {
    return notificationList.some((noti) => noti.read === false);
  }, [notificationList]);

  const renderMessageTitle = useCallback(
    (eventType: NOTIFICATION_EVENT_TYPE_ENUM) => {
      switch (eventType) {
        case NOTIFICATION_EVENT_TYPE_ENUM.APPLY_JOB: {
          return translate('notificationType.applyJob', { ns: 'notification' });
        }
        case NOTIFICATION_EVENT_TYPE_ENUM.INTERVIEW_SCHEDULED: {
          return translate('notificationType.interviewScheduled', { ns: 'notification' });
        }
        case NOTIFICATION_EVENT_TYPE_ENUM.REGISTER: {
          return translate('notificationType.register', { ns: 'notification' });
        }
        case NOTIFICATION_EVENT_TYPE_ENUM.VIEW_CV_REQUEST: {
          return translate('notificationType.viewCVRequest', { ns: 'notification' });
        }
        case NOTIFICATION_EVENT_TYPE_ENUM.CANDIDATE_REJECT_REQUEST: {
          return translate('notificationType.candidateRejectRequest', { ns: 'notification' });
        }
        case NOTIFICATION_EVENT_TYPE_ENUM.ANSWER_INTERVIEW_SCHEDULED: {
          return translate('notificationType.answerInterviewSchedule', { ns: 'notification' });
        }
        case NOTIFICATION_EVENT_TYPE_ENUM.ANSWER_CV_REQUEST: {
          return translate('notificationType.answerCVRequest', { ns: 'notification' });
        }
      }
    },
    [translate],
  );

  const isNewMessage = useCallback((read: boolean) => read === false, []);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="rounded-full relative">
          {havingNewMessage && (
            <IconCircleFilled className="text-red-500 h-4 w-4 absolute -top-1 -right-1 animate-bounce" />
          )}
          <IconBell size={18} className="text-black dark:text-white" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-96" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex  space-y-1 justify-between items-start">
            <div className="grid gap-2">
              <p className="text-sm font-bold leading-none">
                {translate('systems.elements.navigation.elements.notificationsToggle.content.title')}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {translate('systems.elements.navigation.elements.notificationsToggle.content.description')}
              </p>
            </div>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {isLoadingNotifications && <Skeleton className="h-10 w-full" />}
          {!isLoadingNotifications && notificationList.length <= 0 && (
            <div className="p-2 text-xs">{'Chưa có thông báo mới !!'}</div>
          )}
          {notificationList.map((noti) => {
            return (
              <NotificationItem
                key={noti.id}
                isNew={isNewMessage(noti.read)}
                notification={noti}
                hanldeUpdateNotification={hanldeUpdateNotification}
                renderMessageTitle={renderMessageTitle}
              />
            );
          })}
        </DropdownMenuGroup>

        <DropdownMenuSeparator />
        <DropdownMenuLabel className="font-normal">
          <div className="flex justify-between items-center">
            <Button
              variant={'ghost'}
              className="text-sm font-bold leading-none"
              onClick={() => {
                navigate('/notifications');
                setOpen(false);
              }}
            >
              {translate(
                'systems.elements.navigation.elements.notificationsToggle.elements.allNotificationButtonTitle',
              )}
            </Button>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <IconChecks className="hover:text-blue-400" size={20} />
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    {' '}
                    {translate(
                      'systems.elements.navigation.elements.notificationsToggle.tooltips.markAllReadTooltipsTitle',
                    )}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
