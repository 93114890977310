export const PATTERN = {
  PHONE: /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$\b/i,
  HOTLINE: /^(\+?1)?((8|9)(00|33|44|55|66|77|88)[0-9]\d{3})$/gm,
  CITIZENIDENTIFICATION: /^\d{9}(?:\d{3})?$/,
  // eslint-disable-next-line no-useless-escape
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  PASSWORD_MIN_LENGTH_6: /^.{6,}$/,
  URL: /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?\/?$/g,
  HEX_COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  WHITE_SPACE: /^(?!\s*$).+/,
  WHITE_SPACE_WORDS: /^\S*$/,
  REPLACE_NUMBER: /[^\d]/g,
  DATE_REGEX: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
  TEXT_REGEX: /^[a-zA-ZÀ-ỹ ]+$/,
  NO_REDUDANT_WHITE_SPACE: /^\S.*\S$/,
};

export const snackCaseRegex = new RegExp(/[a-zA-Z]+(?:_[a-zA-Z]+)*/);
