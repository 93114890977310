export enum PERMISSION_ENUM {
  BANK_ACCOUNT_INFO_VIEW = 'BANK_ACCOUNT_INFO_VIEW',
  BANK_ACCOUNT_INFO_CREATE = 'BANK_ACCOUNT_INFO_CREATE',
  BANK_ACCOUNT_INFO_UPDATE = 'BANK_ACCOUNT_INFO_UPDATE',
  BANK_ACCOUNT_INFO_DELETE = 'BANK_ACCOUNT_INFO_DELETE',

  CANDIDATE_PROFILE_VIEW = 'CANDIDATE_PROFILE_VIEW',
  CANDIDATE_PROFILE_UPDATE = 'CANDIDATE_PROFILE_UPDATE',

  CANDIDATE_EDUCATION_VIEW = 'CANDIDATE_EDUCATION_VIEW',
  CANDIDATE_EDUCATION_CREATE = 'CANDIDATE_EDUCATION_CREATE',
  CANDIDATE_EDUCATION_UPDATE = 'CANDIDATE_EDUCATION_UPDATE',
  CANDIDATE_EDUCATION_DELETE = 'CANDIDATE_EDUCATION_DELETE',

  CANDIDATE_EVALUATION_COMPANY_VIEW = 'CANDIDATE_EVALUATION_COMPANY_VIEW',
  CANDIDATE_EVALUATION_COMPANY_CREATE = 'CANDIDATE_EVALUATION_COMPANY_CREATE',
  CANDIDATE_EVALUATION_COMPANY_UPDATE = 'CANDIDATE_EVALUATION_COMPANY_UPDATE',
  CANDIDATE_EVALUATION_COMPANY_DELETE = 'CANDIDATE_EVALUATION_COMPANY_DELETE',

  CANDIDATE_EXPERIENCE_VIEW = 'CANDIDATE_EXPERIENCE_VIEW',
  CANDIDATE_EXPERIENCE_CREATE = 'CANDIDATE_EXPERIENCE_CREATE',
  CANDIDATE_EXPERIENCE_UPDATE = 'CANDIDATE_EXPERIENCE_UPDATE',
  CANDIDATE_EXPERIENCE_DELETE = 'CANDIDATE_EXPERIENCE_DELETE',

  JOB_VIEW = 'JOB_VIEW',
  JOB_CREATE = 'JOB_CREATE',
  JOB_UPDATE = 'JOB_UPDATE',
  JOB_DELETE = 'JOB_DELETE',

  CANDIDATE_JOB_APPLY_VIEW = 'CANDIDATE_JOB_APPLY_VIEW',
  CANDIDATE_JOB_APPLY_CREATE = 'CANDIDATE_JOB_APPLY_CREATE',
  CANDIDATE_JOB_APPLY_UPDATE = 'CANDIDATE_JOB_APPLY_UPDATE',
  CANDIDATE_JOB_APPLY_DELETE = 'CANDIDATE_JOB_APPLY_DELETE',

  CANDIDATE_JOB_APPLY_PROCESS_VIEW = 'CANDIDATE_JOB_APPLY_PROCESS_VIEW',
  CANDIDATE_JOB_APPLY_PROCESS_CREATE = 'CANDIDATE_JOB_APPLY_PROCESS_CREATE',
  CANDIDATE_JOB_APPLY_PROCESS_UPDATE = 'CANDIDATE_JOB_APPLY_PROCESS_UPDATE',
  CANDIDATE_JOB_APPLY_PROCESS_DELETE = 'CANDIDATE_JOB_APPLY_PROCESS_DELETE',

  CAREER_CATEGORY_VIEW = 'CAREER_CATEGORY_VIEW',
  CAREER_CATEGORY_CREATE = 'CAREER_CATEGORY_CREATE',
  CAREER_CATEGORY_UPDATE = 'CAREER_CATEGORY_UPDATE',
  CAREER_CATEGORY_DELETE = 'CAREER_CATEGORY_DELETE',

  CAREER_VIEW = 'CAREER_VIEW',
  CAREER_CREATE = 'CAREER_CREATE',
  CAREER_UPDATE = 'CAREER_UPDATE',
  CAREER_DELETE = 'CAREER_DELETE',

  COMPANY_ARTICLE_VIEW = 'COMPANY_ARTICLE_VIEW',
  COMPANY_ARTICLE_CREATE = 'COMPANY_ARTICLE_CREATE',
  COMPANY_ARTICLE_UPDATE = 'COMPANY_ARTICLE_UPDATE',
  COMPANY_ARTICLE_DELETE = 'COMPANY_ARTICLE_DELETE',

  COMPANY_VIEW = 'COMPANY_VIEW',
  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  COMPANY_DELETE = 'COMPANY_DELETE',

  COMPANY_EVALUATION_CANDIDATE_VIEW = 'COMPANY_EVALUATION_CANDIDATE_VIEW',
  COMPANY_EVALUATION_CANDIDATE_CREATE = 'COMPANY_EVALUATION_CANDIDATE_CREATE',
  COMPANY_EVALUATION_CANDIDATE_UPDATE = 'COMPANY_EVALUATION_CANDIDATE_UPDATE',
  COMPANY_EVALUATION_CANDIDATE_DELETE = 'COMPANY_EVALUATION_CANDIDATE_DELETE',

  COMPANY_PROFILE_VIEW = 'COMPANY_PROFILE_VIEW',
  COMPANY_PROFILE_UPDATE = 'COMPANY_PROFILE_UPDATE',

  EMAIL_CONFIG_VIEW = 'EMAIL_CONFIG_VIEW',
  EMAIL_CONFIG_CREATE = 'EMAIL_CONFIG_CREATE',
  EMAIL_CONFIG_UPDATE = 'EMAIL_CONFIG_UPDATE',
  EMAIL_CONFIG_DELETE = 'EMAIL_CONFIG_DELETE',

  EMAIL_TEMPLATE_VIEW = 'EMAIL_TEMPLATE_VIEW',
  EMAIL_TEMPLATE_CREATE = 'EMAIL_TEMPLATE_CREATE',
  EMAIL_TEMPLATE_UPDATE = 'EMAIL_TEMPLATE_UPDATE',
  EMAIL_TEMPLATE_DELETE = 'EMAIL_TEMPLATE_DELETE',

  TRANSACTION_HISTORY_VIEW = 'TRANSACTION_HISTORY_VIEW',
  TRANSACTION_HISTORY_CREATE = 'TRANSACTION_HISTORY_CREATE',
  TRANSACTION_HISTORY_UPDATE = 'TRANSACTION_HISTORY_UPDATE',

  TRANSACTION_REQUEST_VIEW = 'TRANSACTION_REQUEST_VIEW',
  TRANSACTION_REQUEST_CREATE = 'TRANSACTION_REQUEST_CREATE',
  TRANSACTION_REQUEST_UPDATE = 'TRANSACTION_REQUEST_UPDATE',
  TRANSACTION_REQUEST_DELETE = 'TRANSACTION_REQUEST_DELETE',

  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DELETE = 'ROLE_DELETE',

  USER_VIEW = 'USER_VIEW',
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
}

//Sau này nếu đổi PERMISSION thì chỉ cần chạy hàm bên dưới để tạo ra PERMISSION_KEYS để tránh sự sai xót khi gõ tay

// export const PERMISSION_KEYS = Object.keys(PERMISSION_ENUM).reduce((acc, key) => {
//   const [prefix, action] = key.split('_');
//   if (!acc[prefix]) {
//     acc[prefix] = {};
//   }
//   acc[prefix][action] = key;
//   return acc;
// }, {} as Record<string, Record<string, string>>);

// export const PERMISSIONS_ARRAY = Object.values(PERMISSION_ENUM);

export const PERMISSION_KEYS = {
  BANK_ACCOUNT_INFO: {
    VIEW: 'BANK_ACCOUNT_INFO_VIEW',
    CREATE: 'BANK_ACCOUNT_INFO_CREATE',
    UPDATE: 'BANK_ACCOUNT_INFO_UPDATE',
    DELETE: 'BANK_ACCOUNT_INFO_DELETE',
  },
  CANDIDATE_PROFILE: {
    VIEW: 'CANDIDATE_PROFILE_VIEW',
    UPDATE: 'CANDIDATE_PROFILE_UPDATE',
  },
  CANDIDATE_EDUCATION: {
    VIEW: 'CANDIDATE_EDUCATION_VIEW',
    CREATE: 'CANDIDATE_EDUCATION_CREATE',
    UPDATE: 'CANDIDATE_EDUCATION_UPDATE',
    DELETE: 'CANDIDATE_EDUCATION_DELETE',
  },
  CANDIDATE_EVALUATION_COMPANY: {
    VIEW: 'CANDIDATE_EVALUATION_COMPANY_VIEW',
    CREATE: 'CANDIDATE_EVALUATION_COMPANY_CREATE',
    UPDATE: 'CANDIDATE_EVALUATION_COMPANY_UPDATE',
    DELETE: 'CANDIDATE_EVALUATION_COMPANY_DELETE',
  },
  CANDIDATE_EXPERIENCE: {
    VIEW: 'CANDIDATE_EXPERIENCE_VIEW',
    CREATE: 'CANDIDATE_EXPERIENCE_CREATE',
    UPDATE: 'CANDIDATE_EXPERIENCE_UPDATE',
    DELETE: 'CANDIDATE_EXPERIENCE_DELETE',
  },
  JOB: {
    VIEW: 'JOB_VIEW',
    CREATE: 'JOB_CREATE',
    UPDATE: 'JOB_UPDATE',
    DELETE: 'JOB_DELETE',
  },
  CANDIDATE_JOB_APPLY: {
    VIEW: 'CANDIDATE_JOB_APPLY_VIEW',
    CREATE: 'CANDIDATE_JOB_APPLY_CREATE',
    UPDATE: 'CANDIDATE_JOB_APPLY_UPDATE',
    DELETE: 'CANDIDATE_JOB_APPLY_DELETE',
  },
  CANDIDATE_JOB_APPLY_PROCESS: {
    VIEW: 'CANDIDATE_JOB_APPLY_PROCESS_VIEW',
    CREATE: 'CANDIDATE_JOB_APPLY_PROCESS_CREATE',
    UPDATE: 'CANDIDATE_JOB_APPLY_PROCESS_UPDATE',
    DELETE: 'CANDIDATE_JOB_APPLY_PROCESS_DELETE',
  },
  CAREER_CATEGORY: {
    VIEW: 'CAREER_CATEGORY_VIEW',
    CREATE: 'CAREER_CATEGORY_CREATE',
    UPDATE: 'CAREER_CATEGORY_UPDATE',
    DELETE: 'CAREER_CATEGORY_DELETE',
  },
  CAREER: {
    VIEW: 'CAREER_VIEW',
    CREATE: 'CAREER_CREATE',
    UPDATE: 'CAREER_UPDATE',
    DELETE: 'CAREER_DELETE',
  },
  COMPANY_ARTICLE: {
    VIEW: 'COMPANY_ARTICLE_VIEW',
    CREATE: 'COMPANY_ARTICLE_CREATE',
    UPDATE: 'COMPANY_ARTICLE_UPDATE',
    DELETE: 'COMPANY_ARTICLE_DELETE',
  },
  COMPANY: {
    VIEW: 'COMPANY_VIEW',
    CREATE: 'COMPANY_CREATE',
    UPDATE: 'COMPANY_UPDATE',
    DELETE: 'COMPANY_DELETE',
  },
  COMPANY_EVALUATION_CANDIDATE: {
    VIEW: 'COMPANY_EVALUATION_CANDIDATE_VIEW',
    CREATE: 'COMPANY_EVALUATION_CANDIDATE_CREATE',
    UPDATE: 'COMPANY_EVALUATION_CANDIDATE_UPDATE',
    DELETE: 'COMPANY_EVALUATION_CANDIDATE_DELETE',
  },
  COMPANY_PROFILE: {
    VIEW: 'COMPANY_PROFILE_VIEW',
    UPDATE: 'COMPANY_PROFILE_UPDATE',
  },
  EMAIL_CONFIG: {
    VIEW: 'EMAIL_CONFIG_VIEW',
    CREATE: 'EMAIL_CONFIG_CREATE',
    UPDATE: 'EMAIL_CONFIG_UPDATE',
    DELETE: 'EMAIL_CONFIG_DELETE',
  },
  EMAIL_TEMPLATE: {
    VIEW: 'EMAIL_TEMPLATE_VIEW',
    CREATE: 'EMAIL_TEMPLATE_CREATE',
    UPDATE: 'EMAIL_TEMPLATE_UPDATE',
    DELETE: 'EMAIL_TEMPLATE_DELETE',
  },
  TRANSACTION_HISTORY: {
    VIEW: 'TRANSACTION_HISTORY_VIEW',
    CREATE: 'TRANSACTION_HISTORY_CREATE',
    UPDATE: 'TRANSACTION_HISTORY_UPDATE',
  },
  TRANSACTION_REQUEST: {
    VIEW: 'TRANSACTION_REQUEST_VIEW',
    CREATE: 'TRANSACTION_REQUEST_CREATE',
    UPDATE: 'TRANSACTION_REQUEST_UPDATE',
    DELETE: 'TRANSACTION_REQUEST_DELETE',
    CONFIRM: 'TRANSACTION_REQUEST_CONFIRM',
  },
  ROLE: {
    VIEW: 'ROLE_VIEW',
    CREATE: 'ROLE_CREATE',
    UPDATE: 'ROLE_UPDATE',
    DELETE: 'ROLE_DELETE',
  },
  USER: {
    VIEW: 'USER_VIEW',
    CREATE: 'USER_CREATE',
    UPDATE: 'USER_UPDATE',
    DELETE: 'USER_DELETE',
  },
  USER_TYPE_PERMISSION: {
    VIEW: 'USER_TYPE_PERMISSION_VIEW',
    UPDATE: 'USER_TYPE_PERMISSION_UPDATE',
  },
};
